import { tns } from 'tiny-slider';
import { playMedia } from '../../../../../assets/js/utils/playPauseMedia';

const switcherWraps = Array.from(document.querySelectorAll('.js-switcher-wrap'));
const carousels = Array.from(document.querySelectorAll('.js-switcher-carousel'));
const tabSwitchers = Array.from(document.querySelectorAll('.js-switcher-tabs'));

// Accessibility helper
function bindAccessibilityPause(el) {
  el.onfocus = () => {
    el.setAttribute('controls', true);
    el.parentElement.firstChild.classList.add('is-active');
  };
  el.onblur = () => {
    el.removeAttribute('controls');
    playMedia(el);
    el.parentElement.firstChild.classList.remove('is-active');
  };
}

// Finds all the CMS <template> tags and moves them
// This allows CMS edit mode to work
if (switcherWraps.length > 0) {
  switcherWraps.forEach((switcherWrap) => {
    const cmsTemplateTags = Array.from(switcherWrap.querySelectorAll('template'));

    // Finds all the CMS <template> tags
    cmsTemplateTags.forEach((templateTag) => {
      // Copies it
      const copy = templateTag;

      // Removes it from default CMS-dropped spot
      templateTag.remove;

      // Put it inside the parent instead of inside the children
      switcherWrap.append(copy);
    });
  });
}

// Bind any carousels
if (carousels.length > 0) {
  carousels.forEach((carousel) => {
    // Create carousel type from tinyslider
    window.addEventListener('load', (event) => {
      const carouselSlider = tns({
        container: carousel,
        speed: 350,
        mouseDrag: true,
        touch: true,
        controlsText: [
          '<div class="tns-controls__icon"><span class="sr-only">Previous slide</span><svg aria-hidden="true" role="img" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"/></svg></div>',
          '<div class="tns-controls__icon"><span class="sr-only">Next slide</span><svg aria-hidden="true" role="img" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"/></svg></div>',
        ],
      });

      // Fix for tinyslider liveregion "incorrect" slide number readings
      const switcherWrap = carousel.closest('.js-switcher-wrap');
      const switcherControls = Array.from(switcherWrap.querySelectorAll('.tns-controls button'));
      switcherControls.forEach((control) => {
        // Get number of slides visably showing
        const visibleSlides = Array.from(carousel.querySelectorAll('.switcher__item[id]:not([id=""])'));
        const maxSlides = visibleSlides.length;

        // set data attribute to read NEXT/PREV slide number
        for (let i = 1; i <= visibleSlides.length; i++) {
          if (i == visibleSlides.length) {
            visibleSlides[i - 1].dataset.nextReadIndex = 1;
            visibleSlides[i - 1].dataset.prevReadIndex = visibleSlides.length - 1;
          } else if (i == 1) {
            visibleSlides[i - 1].dataset.nextReadIndex = i + 1;
            visibleSlides[i - 1].dataset.prevReadIndex = visibleSlides.length;
          } else {
            visibleSlides[i - 1].dataset.nextReadIndex = i + 1;
            visibleSlides[i - 1].dataset.prevReadIndex = i - 1;
          }
        }

        let activeSlide;
        control.addEventListener('click', (e) => {
          const liveRegion = switcherWrap.querySelector('.tns-liveregion');
          if (control.dataset.controls === 'prev') {
            activeSlide = carousel.querySelector('.tns-slide-active').dataset.prevReadIndex;
          } else {
            activeSlide = carousel.querySelector('.tns-slide-active').dataset.nextReadIndex;
          }
          liveRegion.innerHTML = `<span class='current'>Slide ${activeSlide} of ${maxSlides}</span>`;
        });
      });
    });
  });
}

// Bind any tab switchers
if (tabSwitchers.length > 0) {
  tabSwitchers.forEach((tabSwitcher) => {
    const controls = tabSwitcher.closest('.js-switcher-wrap').querySelector('.js-switcher-controls');

    window.addEventListener('load', (event) => {
      // Create fade slider type from tinyslider
      const fadeSlider = tns({
        container: tabSwitcher,
        // autoplay: true,
        // autoplayTimeout: 4000,
        mode: 'gallery',
        speed: 250,
        navContainer: controls,
        controls: false,
        autoHeight: true,
      });

      // onInit callback is available if needed

      // Select needed elements (based on the created slider)
      const ctx = fadeSlider.getInfo().container;
      const videos = Array.from(ctx.querySelectorAll('video'));
      const videosAutoplay = Array.from(ctx.querySelectorAll('video[autoplay]'));
      const switcherContentBucket = ctx.closest('.js-switcher-wrap').querySelector('.js-switcher-subtext');
      const buttons = Array.from(ctx.closest('.js-switcher-wrap').querySelector('.js-switcher-controls').children);
      buttons.forEach((ele) => {
        ele.addEventListener('focus', (event) => {
          // set all other buttons to tabIndex -1
          buttons.forEach((ele) => {
            ele.setAttribute('tabIndex', '-1');
            ele.setAttribute('aria-selected', 'false');
          });
          // set just this button to tabIndex 0
          ele.setAttribute('tabIndex', '0');
          ele.setAttribute('aria-selected', 'true');
          ele.click();
        });
      });

      // Pause autoplaying, since we want to start it on scroll
      fadeSlider.pause();

      if (videos.length > 0) {
        // Fix Firefox page jumping bug
        // <div class="tns-inner"> height should stop flipping 600px vs 150px
        const tnsInner = ctx.closest('.js-switcher-wrap').querySelector('.tns-inner');
        tnsInner.classList.add('tns-inner-for-video');

        fadeSlider.events.on('transitionStart', (data) => {
          // Change any content from slide meta
          if (switcherContentBucket) {
            const switcherHeadline = Array.from(ctx.querySelectorAll('.js-switcher-meta-head'))[data.index].innerHTML;
            const switcherContent = Array.from(ctx.querySelectorAll('.js-switcher-meta-content'))[data.index].innerHTML;

            if (switcherHeadline.length > 0) {
              switcherContentBucket.querySelector('.poster__head-main').innerHTML = switcherHeadline;
            }
            if (switcherContent.length > 0) {
              switcherContentBucket.querySelector('.poster__details').innerHTML = switcherContent;
            }
          }
        });

        videos.forEach((ele) => {
          bindAccessibilityPause(ele);
        });

        fadeSlider.events.on('transitionEnd', (data) => {
          // Pause video we are coming from
          videos[data.indexCached].setAttribute('tabindex', -1);
          videos[data.indexCached].parentElement.firstChild.setAttribute('tabindex', -1);
          videos[data.indexCached].pause();
          videos[data.indexCached].currentTime = 0;

          // Play video when video we are going to
          videos[data.index].setAttribute('tabindex', 0);
          videos[data.index].parentElement.firstChild.setAttribute('tabindex', 0);
          videos[data.index].currentTime = 0;
          playMedia(videos[data.index]);
        });

        // Trigger a slide change event -- TWO, MAR 2 2018
        // Tinyslider has trouble dynamically setting height w/ currently-loading video
        // Moving slides is the least gross option to do the resize stuff
        // fadeSlider.goTo('prev');

        // This is gross, but ensures correct heights if loaded while *in* viewport
        // Timeout amount is padded from above slide transition duration
        setTimeout(() => {
          fadeSlider.goTo(0);
          playMedia(fadeSlider);
        }, 400);
      }

      // Play video when it's visible
      const waypoint = new Waypoint({
        element: ctx,
        offset: (window.innerHeight) * 0.75,
        handler(direction) {
          if (ctx.classList.contains('was-autoplayed') === false) {
            // Make sure we're on the first slide
            fadeSlider.goTo(0);

            // If any autoplay videos, start playing the first one
            if (videosAutoplay.length > 0) {
              playMedia(videosAutoplay[0]);
            }

            // Start playing the slider, since we stopped it earlier
            playMedia(fadeSlider);

            // Signify we've started playing it
            ctx.classList.add('was-autoplayed');
          }
        },
      });
    });
  });
}
